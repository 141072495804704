import { App, createApp,} from 'vue';
import { reactive } from "vue";
import PrimeVue from "primevue/config";
import AutoComplete from "primevue/autocomplete";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import Avatar from "primevue/avatar";
import AvatarGroup from "primevue/avatargroup";
import Badge from "primevue/badge";
import BadgeDirective from "primevue/badgedirective";
import Button from "primevue/button";
import Breadcrumb from "primevue/breadcrumb";
import Calendar from "primevue/calendar";
import Card from "primevue/card";
import Carousel from "primevue/carousel";
import Chart from "primevue/chart";
import Checkbox from "primevue/checkbox";
import Chip from "primevue/chip";
import Chips from "primevue/chips";
import ColorPicker from "primevue/colorpicker";
import Column from "primevue/column";
import ConfirmDialog from "primevue/confirmdialog";
import ConfirmPopup from "primevue/confirmpopup";
import ConfirmationService from "primevue/confirmationservice";
import ContextMenu from "primevue/contextmenu";
import DataTable from "primevue/datatable";
import DataView from "primevue/dataview";
import DataViewLayoutOptions from "primevue/dataviewlayoutoptions";
import Dialog from "primevue/dialog";
import Divider from "primevue/divider";
import Dropdown from "primevue/dropdown";
import Fieldset from "primevue/fieldset";
import FileUpload from "primevue/fileupload";
import FullCalendar from "primevue/fullcalendar";
import Galleria from "primevue/galleria";
import Image from "primevue/image";
import InlineMessage from "primevue/inlinemessage";
import Inplace from "primevue/inplace";
import InputMask from "primevue/inputmask";
import InputNumber from "primevue/inputnumber";
import InputSwitch from "primevue/inputswitch";
import InputText from "primevue/inputtext";
import Knob from "primevue/knob";
import Listbox from "primevue/listbox";
import MegaMenu from "primevue/megamenu";
import Menu from "primevue/menu";
import Menubar from "primevue/menubar";
import Message from "primevue/message";
import MultiSelect from "primevue/multiselect";
import OrderList from "primevue/orderlist";
import OrganizationChart from "primevue/organizationchart";
import OverlayPanel from "primevue/overlaypanel";
import Paginator from "primevue/paginator";
import Panel from "primevue/panel";
import PanelMenu from "primevue/panelmenu";
import Password from "primevue/password";
import PickList from "primevue/picklist";
import ProgressBar from "primevue/progressbar";
import Rating from "primevue/rating";
import RadioButton from "primevue/radiobutton";
import Ripple from "primevue/ripple";
import SelectButton from "primevue/selectbutton";
import ScrollPanel from "primevue/scrollpanel";
import ScrollTop from "primevue/scrolltop";
import Slider from "primevue/slider";
import Sidebar from "primevue/sidebar";
import Skeleton from "primevue/skeleton";
import SpeedDial from "primevue/speeddial";
import SplitButton from "primevue/splitbutton";
import Splitter from "primevue/splitter";
import SplitterPanel from "primevue/splitterpanel";
import Steps from "primevue/steps";
import StyleClass from "primevue/styleclass";
import TabMenu from "primevue/tabmenu";
import Tag from "primevue/tag";
import TieredMenu from "primevue/tieredmenu";
import Textarea from "primevue/textarea";
import Timeline from "primevue/timeline";
import Toast from "primevue/toast";
import ToastService from "primevue/toastservice";
import Toolbar from "primevue/toolbar";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Tooltip from "primevue/tooltip";
import ToggleButton from "primevue/togglebutton";
import Tree from "primevue/tree";
import TreeSelect from "primevue/treeselect";
import TreeTable from "primevue/treetable";
import TriStateCheckbox from "primevue/tristatecheckbox";
import ProgressSpinner from "primevue/progressspinner";
import Editor from "primevue/editor";

import CodeHighlight   from "../AppCodeHighlight";
import BlockViewer from '../componentes/base/common/primetemplate/BlockViewer.vue';

import ApiNostromo from "../servicios/ApiNostromo";
import VueCookies, { globalCookiesConfig } from "vue3-cookies";

import "primevue/resources/primevue.min.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "prismjs/themes/prism-coy.css";
import HelperException from "./HelperException";
import VueCryptojs from "vue-cryptojs";

import { menu as MenuApp} from '../modelos/menu/Menu';
//import { Router } from "vue-router";
import store from '../almacen/index';
import { Actions } from '../almacen/enums/StoreEnums';
// eslint-disable-next-line
//import dotnet from "../dotnet/dotnet";




/**
 * @description MainGrapper function
 */

 export let app = createApp({});

class MainWrapper {
  /**
   * @description property to share vue instance
   */
 
     
  static init(instance:App<Element>,menusApp:MenuApp[],urlBaseNostromo: string, domain="willplatine.intrasoft.es") {
    
    if (instance){
      app = instance;
    }     
    app.config.globalProperties.$appState = reactive({ fullWidth: false });

    app.config.globalProperties.publicPath = process.env.BASE_URL;

    app.provide("publicPath", app.config.globalProperties.publicPath);

    app.use(VueCookies);
        
    // eslint-disable-next-line
    //dotnet.boot();

    globalCookiesConfig({
      expireTimes: "30d",
      path: "/",
      domain: domain,
      secure: false,
      sameSite: "None",
    });

    //app.use(a);
    // var a = Actions.ADD_BODY_ATTRIBUTE;
    app.use(PrimeVue, {
      locale: {
        startsWith: "Comienza con",
        contains: "Contiene",
        notContains: "No contiene",
        endsWith: "Termina con",
        equals: "Igual",
        notEquals: "No es igual",
        noFilter: "Sin filtro",
        lt: "Menos que",
        lte: "Menos que o igual a",
        gt: "Mayor que",
        gte: "Mayor qué o igual a",
        dateIs: "La fecha es",
        dateIsNot: "La fecha no es",
        dateBefore: "La fecha es anterior",
        dateAfter: "La fecha es posterior",
        clear: "Borrar",
        apply: "Aplicar",
        matchAll: "Coincidir con todo",
        matchAny: "Coincidir con cualquiera",
        addRule: "Agregar regla",
        removeRule: "Eliminar regla",
        accept: "Sí",
        reject: "No",
        choose: "Seleccionar",
        upload: "Subir ficheros",
        cancel: "Cancelar",
        dayNames: [
          "Domingo",
          "Lunes",
          "Martes",
          "Miércoles",
          "Jueves",
          "Viernes",
          "Sábado",
        ],
        dayNamesShort: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sáb"],
        dayNamesMin: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sá"],
        monthNames: [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre",
        ],
        monthNamesShort: [
          "Ene",
          "Feb",
          "Mar",
          "Abr",
          "May",
          "Jun",
          "Jul",
          "Agto",
          "Sep",
          "Oct",
          "Nov",
          "Dic",
        ],
        today: "Hoy",
        weekHeader: "Sem",
        firstDayOfWeek: 1,
        dateFormat: "dd/mm/yy",
        weak: "Débil",
        medium: "Media",
        strong: "Fuerte",
        passwordPrompt: "Introduzca una contraseña",
        emptyFilterMessage: "No se han encontrado resultados",
        emptyMessage: "No hay opciones disponibles",
      },
      ripple: true,
    });
    app.use(ConfirmationService);
    app.use(ToastService);
    //app.use(router);
    app.use(store);

    app.directive("tooltip", Tooltip);
    app.directive("ripple", Ripple);
    app.directive("code", CodeHighlight);
    app.directive("badge", BadgeDirective);
    app.directive("styleclass", StyleClass);

    app.component("Accordion", Accordion);
    app.component("AccordionTab", AccordionTab);
    app.component("AutoComplete", AutoComplete);
    app.component("Avatar", Avatar);
    app.component("AvatarGroup", AvatarGroup);
    app.component("Badge", Badge);
    app.component("Breadcrumb", Breadcrumb);
    app.component("Button", Button);
    app.component("Calendar", Calendar);
    app.component("Card", Card);
    app.component("Carousel", Carousel);
    app.component("Chart", Chart);
    app.component("Checkbox", Checkbox);
    app.component("Chip", Chip);
    app.component("Chips", Chips);
    app.component("ColorPicker", ColorPicker);
    app.component("Column", Column);
    app.component("ConfirmDialog", ConfirmDialog);
    app.component("ConfirmPopup", ConfirmPopup);
    app.component("ContextMenu", ContextMenu);
    app.component("DataTable", DataTable);
    app.component("DataView", DataView);
    app.component("DataViewLayoutOptions", DataViewLayoutOptions);
    app.component("Dialog", Dialog);
    app.component("Divider", Divider);
    app.component("Dropdown", Dropdown);
    app.component("Fieldset", Fieldset);
    app.component("FileUpload", FileUpload);
    app.component("FullCalendar", FullCalendar);
    app.component("Galleria", Galleria);
    app.component("Image", Image);
    app.component("InlineMessage", InlineMessage);
    app.component("Inplace", Inplace);
    app.component("InputMask", InputMask);
    app.component("InputNumber", InputNumber);
    app.component("InputSwitch", InputSwitch);
    app.component("InputText", InputText);
    app.component("Knob", Knob);
    app.component("Listbox", Listbox);
    app.component("MegaMenu", MegaMenu);
    app.component("Menu", Menu);
    app.component("Menubar", Menubar);
    app.component("Message", Message);
    app.component("MultiSelect", MultiSelect);
    app.component("OrderList", OrderList);
    app.component("OrganizationChart", OrganizationChart);
    app.component("OverlayPanel", OverlayPanel);
    app.component("Paginator", Paginator);
    app.component("Panel", Panel);
    app.component("PanelMenu", PanelMenu);
    app.component("Password", Password);
    app.component("PickList", PickList);
    app.component("ProgressBar", ProgressBar);
    app.component("RadioButton", RadioButton);
    app.component("Rating", Rating);
    app.component("SelectButton", SelectButton);
    app.component("ScrollPanel", ScrollPanel);
    app.component("ScrollTop", ScrollTop);
    app.component("Slider", Slider);
    app.component("Sidebar", Sidebar);
    app.component("Skeleton", Skeleton);
    app.component("SpeedDial", SpeedDial);
    app.component("SplitButton", SplitButton);
    app.component("Splitter", Splitter);
    app.component("SplitterPanel", SplitterPanel);
    app.component("Steps", Steps);
    app.component("TabMenu", TabMenu);
    app.component("TabView", TabView);
    app.component("TabPanel", TabPanel);
    app.component("Tag", Tag);
    app.component("Textarea", Textarea);
    app.component("TieredMenu", TieredMenu);
    app.component("Timeline", Timeline);
    app.component("Toast", Toast);
    app.component("Toolbar", Toolbar);
    app.component("ToggleButton", ToggleButton);
    app.component("Tree", Tree);
    app.component("TreeSelect", TreeSelect);
    app.component("TreeTable", TreeTable);
    app.component("TriStateCheckbox", TriStateCheckbox);
    app.component("ProgressSpinner", ProgressSpinner);
    app.component("Editor", Editor), app.component("BlockViewer", BlockViewer);

    app.config.errorHandler = (err, vm, info) => {
      // err: error trace
      // vm: component in which error occured
      // info: Vue specific error information such as lifecycle hooks, events etc.

      // TODO: Perform any custom logic or log to server
      HelperException.logException(err, vm, info);
    };

    window.onerror = function (message, source, lineno, colno, error) {
      // TODO: write any custom logic or logs the error
      HelperException.logExceptionW(message, source, lineno, colno, error);
    };

    app.use(VueCryptojs);
    
    ApiNostromo.init(app, urlBaseNostromo,store);        
    app.mount("#app");
    
    store.dispatch(Actions.SET_MENU, menusApp)
  }
}

export default MainWrapper;
