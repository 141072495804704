import { getCommunicationsRequest } from '../interfaces/nostromo/service/getRfpNotificationRequest';
import { getCommunicationsResponse } from '../interfaces/nostromo/service/getCommunicationsResponse'
import { setCommunicationsRequest } from '../interfaces/nostromo/service/setCommunicationsRequest';
import { sendCompanyMessageRequest } from '../interfaces/nostromo/service/sendCompanyMessageRequest';
import { responseBase } from '../interfaces/nostromo/service/generic/responseBase';


import ApiNostromo from "../servicios/ApiNostromo";

const GetCommunications = (request: getCommunicationsRequest): Promise<getCommunicationsResponse> => {
    return new Promise<getCommunicationsResponse>((resolve, reject) => {        
        ApiNostromo.post('/rfp/getCommunications', request as any)
            .then((response) => {
                resolve(response.data);
            })
            .catch(() => {
                reject();
        });
    });
}

const SetCommunications = (request: setCommunicationsRequest): Promise<responseBase> => {
    return new Promise<responseBase>((resolve, reject) => {
        ApiNostromo.post('/rfp/setCommunications', request as any)
            .then((response) => {

                resolve(response.data);
            })
            .catch(() => {
                reject();
        });
    });
}

const sendCompanyMessage = (sessionId,id,mensaje ): Promise<responseBase> => {
    return new Promise<responseBase>((resolve, reject) => {
        const request: Partial<sendCompanyMessageRequest>={
            sessionID: sessionId,
            noteId:id,
            message:mensaje,
        };
        ApiNostromo.post('/notification/sendCompanyMessage', request as any)
            .then((response) => {
                resolve(response.data);
            }).catch(() => {
                reject();
            });
    
    });
}
export default {
    GetCommunications,
    SetCommunications,
    sendCompanyMessage
};