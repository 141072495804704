import { App } from 'vue';

import axios from 'axios';
import VueAxios from 'vue-axios';
import { AxiosResponse, AxiosRequestConfig } from 'axios';

import HelperCommon from '@frontcommon/shared/src/funciones/HelperCommon';
import HelperCrypto from '@frontcommon/shared/src/funciones/HelperCrypto';
import HelperUtils from '@frontcommon/shared/src/funciones/HelperUtils';
import { message } from '../interfaces/nostromo/service/generic/message';
import MessageService, { MessageType } from './MessageService';


/**
 * @description service to call HTTP request via Axios
 */
class ApiNostromo {
    /**
     * @description property to share vue instance
     */
    public static vueInstance: App;  
    public static store: any;  
    
    /**
     * @description initialize vue axios
     */
    public static init(app: App<Element>, urlBase: string, store:any) {        
        ApiNostromo.store=store;
        ApiNostromo.vueInstance = app;
        ApiNostromo.vueInstance.use(VueAxios, axios);
        
        ApiNostromo.vueInstance.axios.defaults.baseURL = urlBase; // process.env.BASE_URL;// "http://localhost";                
    }        

    /**
     * @description send the GET HTTP request
     * @param resource: string
     * @param params: AxiosRequestConfig
     * @returns Promise<AxiosResponse>
     */
    public static query(resource: string, params: AxiosRequestConfig): Promise<AxiosResponse> {
        return ApiNostromo.vueInstance.axios.get(resource, params).catch((error) => {
            throw new Error(`ApiNostromo ${error}`);
        });
    }

    /**
     * @description send the GET HTTP request
     * @param resource: string
     * @param slug: string
     * @returns Promise<AxiosResponse>
     */
    public static async get(resource: string, slug = '' as string): Promise<AxiosResponse> {        
        const result = await ApiNostromo.vueInstance.axios.get(`${resource}/${slug}`).catch((error) => {
            throw new Error(`ApiNostromo ${error}`);
        });             
        const decryptedText = HelperCrypto.Decrypt(resource, result.data, this.store)        
        result.data = HelperCommon.jsonIfParse(decryptedText, result.data);
        HelperUtils.handleDates(result);
        return result;         
    }

    public static base64ToArrayBuffer(base64) {
        const binary_string = window.atob(base64);
        const len = binary_string.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
          bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
      }
      
     public static arrayBufferToBase64(buffer) {
        let binary = '';
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
          binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
      }
      
      public static async importPublicKey(spki) {
        const binaryDer = ApiNostromo.base64ToArrayBuffer(spki);
        const cryptoKey = await window.crypto.subtle
          .importKey(
            "spki",
            binaryDer, {
              name: 'RSA-OAEP',
              //modulusLength: 256,
              hash: {
                name: 'sha-256'
              }
            },
            false,
            ["encrypt"]
          );
        return cryptoKey;
      }
      
    /**
     * @description set the POST HTTP request
     * @param resource: string
     * @param params: AxiosRequestConfig
     * @returns Promise<AxiosResponse>
     */
    public static async post(resource: string, params: AxiosRequestConfig): Promise<AxiosResponse> {        
        const paramsBase64 = await HelperCrypto.Encrypt(resource, params, this.store);        
        const result = await ApiNostromo.vueInstance.axios.post(`${resource}`, paramsBase64).catch((error) => {
            throw new Error(`ApiNostromo ${error}`);
        });
        const decryptedText = HelperCrypto.Decrypt(resource, result.data, this.store)        
        result.data = HelperCommon.jsonIfParse(decryptedText, result.data);
        HelperUtils.handleDates(result);        
        return result;
    }

    /**
     * @description send the UPDATE HTTP request
     * @param resource: string
     * @param slug: string
     * @param params: AxiosRequestConfig
     * @returns Promise<AxiosResponse>
     */
    public static update(resource: string, slug: string, params: AxiosRequestConfig): Promise<AxiosResponse> {
        return ApiNostromo.vueInstance.axios.put(`${resource}/${slug}`, params).catch((error) => {
            throw new Error(`ApiNostromo ${error}`);
        });
    }

    /**
     * @description Send the PUT HTTP request
     * @param resource: string
     * @param params: AxiosRequestConfig
     * @returns Promise<AxiosResponse>
     */
    public static put(resource: string, params: AxiosRequestConfig): Promise<AxiosResponse> {
        return ApiNostromo.vueInstance.axios.put(`${resource}`, params).catch((error) => {
            throw new Error(`ApiNostromo ${error}`);
        });
    }

    /**
     * @description Send the DELETE HTTP request
     * @param resource: string
     * @returns Promise<AxiosResponse>
     */
    public static delete(resource: string): Promise<AxiosResponse> {
        return ApiNostromo.vueInstance.axios.delete(resource).catch((error) => {
            throw new Error(`ApiNostromo ${error}`);
        });
    }
}

export default ApiNostromo;
