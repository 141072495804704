import ApiNostromo from '../servicios/ApiNostromo';
import { getCatalogRequest } from '../interfaces/nostromo/service/getCatalogRequest';
import { catalog } from '../interfaces/nostromo/service/generic/catalog';
import { town } from '../interfaces/nostromo/service/generic/town';
import { getTownsRequest } from '../interfaces/nostromo/service/getTownsRequest';
import { insuranceLine } from '../interfaces/nostromo/service/generic/insuranceLine';
import { requestBase } from '../interfaces/nostromo/service/generic/requestBase';

export const ENDPOINT_GET = '/catalog/getCatalog';
export const ENDPOINT_GET_INSURANCE_LINES = '/catalog/getInsuranceLines';
export const ENDPOINT_GET_TOWNS = '/catalog/getTowns';


const getCatalog = (sessionID, types: string[], forIntegration: boolean = false): Promise<catalog[]> => {
    return new Promise<catalog[]>((resolve, reject) => {
        if (sessionID == '') {
            resolve([]);
        } else {
            const request: Partial<getCatalogRequest> = {
                sessionID: sessionID,
                forIntegration: forIntegration,
                types: types,
            };
            ApiNostromo.post(ENDPOINT_GET, request as any)
                .then((response) => {
                    resolve(response.data.catalogs ?? []);
                })
                .catch(() => {
                    reject();
                });
        }
    });
};
const getInsuranceLines = (sessionID: string): Promise<insuranceLine[]> => {
    return new Promise<insuranceLine[]>((resolve, reject) => {
        if (sessionID == '') {
            resolve([]);
        } else {
            const request: Partial<requestBase> = {
                sessionID: sessionID,                
            };
            ApiNostromo.post(ENDPOINT_GET_INSURANCE_LINES, request as any)
                .then((response) => {
                    resolve(response.data.insuranceLines ?? []);
                })
                .catch(() => {
                    reject();
                });
        }
    });
};


const getTowns = (sessionID, zipcodes: string[]): Promise<town[]> => {
    return new Promise<town[]>((resolve, reject) => {
        if (sessionID == '') {
            resolve([]);
        } else {
            const request: Partial<getTownsRequest> = {
                sessionID: sessionID,
                zipCodes:zipcodes
                
            };
            ApiNostromo.post(ENDPOINT_GET_TOWNS, request as any)
                .then((response) => {
                    resolve(response.data.towns ?? []);
                })
                .catch(() => {
                    reject();
                });
        }
    });
};




export default {
    getCatalog,
    getTowns,
    getInsuranceLines
};
