<template>
    <div class="layout-topbar">
        <a class="layout-menu-button" href="#" @click="onMenuButtonClick">
            <i class="pi pi-bars"></i>
        </a>

        <ul class="layout-topbar-menu" style="display: none">
            <li :class="[{ 'active-topmenuitem': activeTopbarItem === 'settings' }]" class="layout-topbar-item" @click="$emit('topbar-item-click', { originalEvent: $event, item: 'settings' })">
                <a class="layout-topbar-icon first">
                    <i class="topbar-icon pi pi-cog"></i>
                </a>
                <transition name="layout-submenu-container">
                    <ul class="fadeInDown" v-show="activeTopbarItem === 'settings'">
                        <li>
                            <a href="#" class="layout-topbar-icon" @click="goToSinkroniza">
                                <i class="topbar-icon pi pi-shield"></i>
                            </a>
                            <div class="layout-quickmenu-tooltip">
                                <div class="layout-quickmenu-tooltip-arrow"></div>
                                <div class="layout-quickmenu-tooltip-text">Sinkroniza Pass</div>
                            </div>
                        </li>
                        <li>
                            <a href="#" class="layout-topbar-icon" @click="goToPortal">
                                <i class="topbar-icon pi pi-clone"></i>
                            </a>
                            <div class="layout-quickmenu-tooltip">
                                <div class="layout-quickmenu-tooltip-arrow"></div>
                                <div class="layout-quickmenu-tooltip-text">Portal administración</div>
                            </div>
                        </li>
                        <li>
                            <a href="#" class="layout-topbar-icon" >
                                <i class="topbar-icon pi pi-sign-out"></i>
                            </a>
                            <div class="layout-quickmenu-tooltip">
                                <div class="layout-quickmenu-tooltip-arrow"></div>
                                <div class="layout-quickmenu-tooltip-text">Salir</div>
                            </div>
                        </li>
                    </ul>
                </transition>
            </li>
        </ul>
    </div>
</template>

<script>
import store from '@frontcommon/shared/src/almacen/index';

export default {
    data() {
        return {
            urlportal: process.env.VUE_APP_PORTAL_URL,
            urlsalesmanager: process.env.VUE_APP_SALESMANAGER_URL,
        };
    },
    emits: ['topbar-item-click', 'menubutton-click', 'topbar-menubutton-click'],
    props: {
        topbarMenuActive: Boolean,
        activeTopbarItem: String,
    },
    methods: {
        onMenuButtonClick(event) {
            this.$emit('menubutton-click', event);
        },
        onTopbarMenuButtonClick(event) {
            this.$emit('topbar-menubutton-click', event);
        },
        goToPortal: function () {
            store.getters.encrypt('sid=' + store.getters.sessionID)
                .then(({ data }) => {
                    window.open(process.env.VUE_APP_PORTAL_URL + '?query=' + data, '_blank');
                })
            
        },
        goToSinkroniza: function () {
            window.open(process.env.VUE_APP_SINkRoNIZA_PASS_URL, '_blank');
        },       
    },
    computed: {
        userName() {
            return store.getters.currentUser.name;
        },
        userEmail() {
            return store.getters.currentUser.email;
        },
        getBrokerLogo() {
            return store.getters.currentBrokerLogo ?? '';
        },
    },
};
</script>
