<template>
    <div class="layout-actionbar">
        <div v-if="getBrokerLogo" class="formgroup-inline">
            <a href="#">
                <img class="logo" style="max-height: 30px;" :src="getBrokerLogo" alt="Logo del corredor"
                    @click="$router.push({ path: '/' })" />
            </a>
            <div class="layout-breadcrumb layout-breadcrumb-ajust">
                <span v-if="$route.meta.breadcrumb && $route.meta.breadcrumb[0]">
                    {{ $route.meta.breadcrumb[0].parent }}
                    <span v-if="$route.meta.breadcrumb[0].label"> <i class="pi pi-fw pi-chevron-right mr-1"
                            style="font-size: 0.8rem"></i> </span>{{ $route.meta.breadcrumb[0].label }}
                </span>
            </div>
        </div>
        <div v-else>
            <i class="pi pi-spin pi-spinner"></i>
        </div>
        <div class="layout-actions">
            <ul>

                <li>
                    <button id="portal" v-tooltip.bottom="{ value: 'Portal administración' }" @click="irAlPortal"><i
                            class="pi pi-external-link icon"></i></button>
                </li>
                <li>
                    <button id="salesmanager" v-tooltip.bottom="{ value: 'Sales Manager' }"
                        @click="mostrarSalesManager"><i class="pi pi-fw pi-sitemap icon"></i></button>
                </li>
                <li>
                    <button id="comment" v-tooltip.bottom="{ value: 'Reportar sugerencias' }"
                        @click="openNewSuggest($event,false)"><i class="pi pi-comment icon"></i></button>
                </li>
                <li>
                    <button id="reportar" v-tooltip.bottom="{ value: 'Reportar incidencia' }"><i
                            class="pi pi-exclamation-triangle icon" @click="openNewSuggest($event,true)"></i></button>
                </li>
                <li v-if="notificaciones">
                    <button id="notificar" v-tooltip.bottom="{ value: 'Notificaciones' }" @click="goToNotificaciones()">
                        <i class="pi pi-bell icon"></i>
                        <Badge v-if="notificaciones.length > 0" :value="notificaciones.length" severity="warning"></Badge>                        
                    </button>
                </li>
                <li v-if="correosConError && correosConError.length > 0">
                    <button id="correosConError" v-tooltip.bottom="{ value: 'Envío de correos erróneos' }" @click="goToNotificaciones('EmailErrors')">
                        <i class="pi pi-envelope icon"></i>                        
                        <Badge v-if="correosConError.length > 0" :value="correosConError.length" severity="danger"></Badge>
                    </button>
                </li>
                <li v-if="saveInProgress">
                    <button id="saving" v-tooltip.bottom="{ value: 'Grabando datos' }"><i
                            class="color-correduria pi pi-spin pi-save icon"></i></button>
                </li>
                <li v-if="userName">
                    <div class="user-info">
                        <router-link to="/miperfil"><span title="salir"></span>{{ userName }}</router-link>
                    </div>
                </li>
                <li v-if="userName">
                    <div class="user-info">
                        <a href="#" class="layout-topbar-icon" v-tooltip.left="{ value: 'Salir' }" @click="salir">
                            <i class="topbar-icon pi pi-sign-out"></i>
                        </a>
                    </div>
                </li>
                <li v-else>
                    <i class="pi pi-spin pi-spinner"></i>
                </li>


            </ul>
        </div>
    </div>
    <Cargando ref="cargando"></Cargando>
    <OverlayPanel ref="newsuggest" :dismissable="true" appendTo="body" :showCloseIcon="true" id="newsuggest"
        style="width: 60vw" :breakpoints="{ '960px': '75vw' }">
        <Sugerencias :isError="isError" @doClose="closeSuggest" />
    </OverlayPanel>
</template>

<script lang="ts">

import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { getCommunicationsRequest } from '@frontcommon/shared/src/interfaces/nostromo/service/getRfpNotificationRequest';
import HelperCommon from '@frontcommon/shared/src/funciones/HelperCommon';
import EventBus from '@frontcommon/shared/src/event-bus';
import EventConst from '@frontcommon/shared/src/constantes/EventConst';
import Cargando from '../../../../shared/src/componentes/base/common/cargando/Cargando.vue';
import Sugerencias from '../../../../shared/src/componentes/base/portal/inicio/Sugerencias.vue';
import HelperExternal from '@frontcommon/shared/src/funciones/HelperExternal';
import HelperNotificaciones from '@frontcommon/shared/src/funciones/HelperNotificaciones';
import { communication } from '@frontcommon/shared/src/interfaces';

export default {
    name: 'AppActionBar',
    components: {
        Cargando,
        Sugerencias
    },
    setup() {
        const store = useStore();
        const router = useRouter();
        const saveInProgress = ref(false);
        const cargando = ref();
        const newsuggest = ref();
        const isError = ref(false);
        const notificaciones = ref<communication[]>();        
        let showTarificador = ref(false);


        if (store.getters.isUserAuthenticated) {
            getData();
            setInterval(function () {
                getData();
            }, 600000);
        }

        const correosConError = computed(() => {
            return notificaciones.value?.filter(c => c.rfpMailEvent != null)            
        }) 

        const isAdmin = computed( () => {
            return store.getters?.currentUser?.broker?.isAdmin;
        });

        function goToNotificaciones(tipo: string | null = null) {            
            router.push({ name: 'notificaciones', params: { tipo: tipo} });            
        }
        function getData() {
            if (store.getters.isUserAuthenticated) {
                const request:getCommunicationsRequest = ({
                    sessionID: store.getters.sessionID,
                    all: false,
                    id: '',
                    bcFleetRequestId: '',
                    rfpId: '',
                    rfpVersion: null,
                    createdByUserId: '',
                    notificateToUserId: store.getters.currentUser.id,
                    fromNotificationDate: '',
                    toNotificationDate: '',
                    seen: false,
                });

                HelperNotificaciones.GetCommunications(request).then((response) => {  
                        notificaciones.value = response.communications;
                    });
            }
        }


        const irAlPortal = () => {
            store.getters.encrypt('sid=' + store.getters.sessionID)
                .then(({ data }) => {
                    window.open(process.env.VUE_APP_PORTAL_URL + '?query=' + encodeURIComponent(data), '_blank');
                })

        };

        const mostrarSalesManager = () => {
            cargando.value.mostrar();
            HelperExternal.getExternalUrl(store.getters.sessionID, store.getters.currentUser.id, store.getters.currentUser.broker.id, 'avant')
                .then((url) => {
                    if (url) {
                        window.open(url, '_blank');
                    }
                    else {
                        window.open(process.env.VUE_APP_SALESMANAGER_URL ?? '', '_blank');
                    }
                }).finally(() => {
                    cargando.value.ocultar();
                })

        };

        function salir() {
            HelperCommon.salir(store.getters.sessionID);
        }


        const userName = computed(() => {
            return store.getters.currentUser.name;
        });
        const userEmail = computed(() => {
            return store.getters.currentUser.email;
        });
        const getBrokerLogo = computed(() => {
            return store.getters.currentBrokerLogo ?? '';
        });

        const openNewSuggest = (event, error) => {
            if (newsuggest.value) {
                newsuggest.value.toggle(event);
                isError.value = error;
            }
        }

        const closeSuggest = () => {
            if (newsuggest.value) {
                newsuggest.value.hide();
                isError.value = false;
            }
        }

        


        onMounted(() => {
            EventBus.on(EventConst.SAVE, (saving: boolean) => {
                if (saving) {
                    saveInProgress.value = saving;
                }
                else {
                    setTimeout(() => {
                        saveInProgress.value = saving
                    }, 1000);
                }
            });
            EventBus.on(EventConst.LOADING, (show: boolean) => {
                if (show) {
                    if (cargando.value) {
                        cargando.value.mostrar();
                    }

                }
                else {
                    if (cargando.value) {
                        cargando.value.ocultar();
                    }
                }
            });

        })



        return {
            correosConError,
            notificaciones,
            goToNotificaciones,
            getBrokerLogo,
            userName,
            userEmail,
            salir,
            showTarificador,

            irAlPortal,
            mostrarSalesManager,
            saveInProgress,
            cargando,
            newsuggest,
            openNewSuggest,
            closeSuggest,
            isError,
            isAdmin
        };
    },
};
</script>

<style scoped>

</style>
