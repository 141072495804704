import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppSubmenu = _resolveComponent("AppSubmenu")!

  return (_openBlock(), _createBlock(_component_AppSubmenu, {
    class: "layout-menu clearfix",
    items: _ctx.filterMenu,
    layoutMode: _ctx.layoutMode,
    menuActive: _ctx.active,
    root: true,
    parentMenuItemActive: true
  }, null, 8 /* PROPS */, ["items", "layoutMode", "menuActive"]))
}