<template>    
    <Toast position="bottom-right" style="z-index:8888888888888" />    
    <div :class="containerClass" @click="onDocumentClick" id="contenedorwp">
        <div class="layout-content-wrapper">
            <div id="topbaractions">                
                <AppTopBar :topbarMenuActive="topbarMenuActive" :horizontal="layoutMode === 'horizontal'"
                    :activeTopbarItem="activeTopbarItem" @menubutton-click="onMenuButtonClick"
                    @topbar-menubutton-click="onTopbarMenuButtonClick" @topbar-item-click="onTopbarItemClick">
                </AppTopBar>
            </div>

            <div class="layout-menu-container" @click="onMenuClick">
                <div class="overlay-menu-button" @click="onMenuButtonClick">
                    <div class="overlay-menu-button-bars">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <div class="overlay-menu-button-times">
                        <span></span>
                        <span></span>
                    </div>
                </div>

                <div class="layout-menu-wrapper fadeInDown">
                    <AppMenu :layoutMode="layoutMode" :active="menuActive" @menuitem-click="onMenuItemClick"
                        @root-menuitem-click="onRootMenuItemClick"></AppMenu>
                </div>
            </div>
            <div id="dvHeaderFixed" class="stickyCss">
                <div v-if="isUserAuthenticated">
                    <AppActionBar />
                </div>

            </div>
            <div class="layout-content custom-content">
                <router-view v-slot="{ Component }">
                    <keep-alive include="solicitudes">
                        <component :is="Component" />
                    </keep-alive> 
                </router-view>
            </div>
            <AppFooter v-if="isUserAuthenticated" />
            <div class="layout-mask"></div>
        </div>
        <ScrollTop :threshold="200" />
    </div>
    <Vidle @idle="onidle" @remind="onremind" :loop="false" :reminders="[60]" :wait="5" :duration="60000" />
</template>


<script>

import AppTopBar from './AppTopbar.vue';
import { ref, reactive } from 'vue';
import AppMenu from './AppMenu.vue';
import AppActionBar from './AppActionBar';
import AppFooter from './AppFooter.vue';
import EventBus from '@frontcommon/shared/src/event-bus';
import ApiNostromo from '@frontcommon/shared/src/servicios/ApiNostromo';
import CookieService from '@frontcommon/shared/src/servicios/CookieService';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import HelperSecurity from '@frontcommon/shared/src/funciones/HelperSecurity';
import HelperUtils from '@frontcommon/shared/src/funciones/HelperUtils';
import HelperException from '@frontcommon/shared/src/funciones/HelperException';

import { useStore } from 'vuex';
import HelperCommon from '@frontcommon/shared/src/funciones/HelperCommon';
import ConfigurationConst from '@frontcommon/shared/src/constantes/ConfigurationConst';
import MessageService, { MessageType } from '@frontcommon/shared/src/servicios/MessageService';
import Vidle from '@frontcommon/shared/src/componentes/base/common/idle/Vidle.vue';
import HelperUser from '@frontcommon/shared/src/funciones/HelpeUser';

export default {
    props: {
        theme: String,
        layout: String,
        sessionId: String            
    },
    data() {
        return {                       
            layoutMode: 'horizontal',
            wrapperMode: false,
            overlayMenuActive: false,
            mobileMenuActive: false,
            topbarMenuActive: false,
            activeTopbarItem: null,
            menuActive: false,
            configActive: false,
            configClick: false,
            windowWidth: 0,
            visibleTarificador: false,
            itemsTabs: reactive([]),
            selectedTab: ref(0),
            store: useStore(),
        };
    },
    watch: {
        $route() {  
            this.menuActive = false;
            this.$toast.removeAllGroups();
            if (!this.store.getters.isUserAuthenticated) {
                this.removeAllTabs();
                this.selectedTab = 0;
                this.$router.push({ name: 'login' });
            }
            else {
                if (this.$router.currentRoute.value.path == '/login') {
                    this.removeAllTabs();
                    this.selectedTab = 0;
                }
                else {
                    //this.addTab();
                    if (HelperUser.hasExpiredPassword(this.store.getters.currentUser) && this.$router.currentRoute.value.path !== '/miperfil')
                    {
                        var doCallbackOk = () => {
                            this.$router.push({ name: 'miperfil',params: { operation: 'reestablecerpwd'} });
                        }
                        MessageService.showMessage(MessageType.Aviso, '', 'Su contraseña ha expirado. Debe establecer una nueva contraseña.', true, false, false, '', doCallbackOk, null, 10000);
                    }
                }

            }

        },
    },

    mounted() {      
        
        if (this.getCookieDomnain) {

            const cookieScript = document.createElement("script");
            cookieScript.setAttribute(
                "src",
                "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
            );
            cookieScript.setAttribute(
                "data-domain-script",
                this.getCookieDomnain
            );

            document.head.appendChild(cookieScript);

            const str = '<button id="ot-sdk-btn" class="ot-sdk-show-settings" style="display:inline-block;text-align:center;position:fixed;z-index:9998;left:5px;bottom:8px;">Cookie Settings</button>';
            var temp = document.createElement('div');
            temp.innerHTML = str;

        }      
        /*
                 window.addEventListener('beforeunload', (event) => {
                // Cancel the event as stated by the standard.
                    event.preventDefault();
                    // Chrome requires returnValue to be set.
                    event.returnValue = '';
                    if (this.store.getters.sessionID){
                        HelperCommon.salir(this.store.getters.sessionID);
                    }        
              });
              */

      /*
              window.onscroll = function () { myFunction() };

        var header = document.getElementById("dvHeaderFixed");

        var sticky = header.offsetTop;

        function myFunction() {
            // cabecera
            if (window.pageYOffset > sticky) {
                header.classList.add("sticky");
            } else {
                header.classList.remove("sticky");
            }
        }
        */

        this.$nextTick(function () {
            window.addEventListener('resize', this.getWindowWidth);

            this.getWindowWidth();
        });

        if (!this.store.getters.isUserAuthenticated) {
            var value = window.location.pathname.length > 1 ? window.location.pathname.substr(1) : 'login';
            var query = window.location.search ?? '';            
            if (!value.includes('login') && window.location.href.includes('/rfps/?query=')) {
                CookieService.setCookie(CookieService.COOKIE_REDIRECT_URL, ('/' + value + query), 60 * 5); // 5 minutos
            } else {
                CookieService.destroy(CookieService.COOKIE_REDIRECT_URL);
            }         
            this.$router.push({ name: 'login' });
        }
        else {
            //this.addTab();
        }

    },
    methods: {

        onidle() {
            var doCallbackOk = () => {
                HelperCommon.salir(this.store.getters.sessionID);
            }
            MessageService.showMessage(MessageType.Aviso, '', 'Se ha superado el tiempo de inactividad y se va a cerrar la sesión automáticamente.', false, false, false, '', doCallbackOk, null, 10000);
        },
        onremind(time) {
            MessageService.showToast(MessageType.Aviso, '', 'Faltan ' + time + ' segundos para cerrar la sesión por inactividad.');
        },
        isUserAuthenticated() {            
            return this.store.getters.isUserAuthenticated;
        },
        generateKey() {
            return HelperUtils.newGuid();
        },
        addTab(menu = null) {

            const key = menu?.to ?? this.$route.path;
            const index = this.findTab(key);
            const menus = this.store.getters.appMenu;
            if (index < 0) {
                const mnu = menu ?? HelperSecurity.findMenu(key,menus);
                if (this.itemsTabs.value) {
                    this.itemsTabs.value.push(mnu);
                    this.selectedTab++;
                }
                else {
                    this.itemsTabs.value = [mnu];
                    this.selectedTab = 0;
                }
            }
            else {
                this.navigateTab(index);
            }
        },
        findTab(name) {
            return this.getItemsTabs.findIndex(x => x.to == name);
        },
        removeAllTabs() {
            this.itemsTabs.value = [];
        },

        changeTab(event) {
            this.navigateTab(event.index);
        },

        navigateTab(index) {

            this.selectedTab = index;
            const _to = this.getItemsTabs[index].to;
            if (this.$route.to != _to) {
                this.$router.push({ path: _to });
                this.menuActive = false;
                EventBus.emit('reset-active-index');
                this.hideOverlayMenu();
                if (this.isHorizontal()) {
                    this.menuActive = false;
                }
            }

        },
        removeTab(index) {
            this.itemsTabs.value = this.getItemsTabs.splice(index, 1);
        },


        getWindowWidth() {
            this.windowWidth = document.documentElement.clientWidth;
            if (this.windowWidth < 991) {
                this.$appState.fullWidth = true;
            } else {
                this.$appState.fullWidth = false;
            }
        },
        onDocumentClick() {
            if (!this.topbarItemClick) {
                this.activeTopbarItem = null;
                this.topbarMenuActive = false;
            }

            if (!this.menuClick) {
                if (this.isHorizontal()) {
                    this.menuActive = false;
                    EventBus.emit('reset-active-index');
                }

                this.hideOverlayMenu();
            }

            if (this.configActive && !this.configClick) {
                this.configActive = false;
            }

            this.topbarItemClick = false;
            this.menuClick = false;
            this.configClick = false;
        },
        onProfileClick(event) {
            this.profileExpanded = !this.profileExpanded;
            if (this.isHorizontal()) {
                EventBus.emit('reset-active-index');
            }

            event.preventDefault();
        },
        onMenuClick() {
            this.menuClick = true;
        },
        onMenuItemClick(event) {

            if (!event.item.items) {

                this.addTab(event.item);
                EventBus.emit('reset-active-index');
                this.hideOverlayMenu();

                if (this.isHorizontal()) {
                    this.menuActive = false;
                }
            }
        },
        onRootMenuItemClick() {
            this.menuActive = !this.menuActive;
        },
        onMenuButtonClick(event) {

            this.menuClick = true;

            this.topbarMenuActive = false;
            if (this.isDesktop()) {
                if (this.layoutMode === 'overlay') {
                    this.overlayMenuActive = !this.overlayMenuActive;
                }
            } else {
                this.mobileMenuActive = !this.mobileMenuActive;
            }

            event.preventDefault();
        },
        onTopbarMenuButtonClick(event) {
            this.topbarItemClick = true;
            this.topbarMenuActive = !this.topbarMenuActive;
            this.hideOverlayMenu();
            event.preventDefault();
        },
        onTopbarItemClick(event) {

            this.topbarItemClick = true;

            if (this.activeTopbarItem === event.item) this.activeTopbarItem = null;
            else this.activeTopbarItem = event.item;

            event.originalEvent.preventDefault();
        },
        hideOverlayMenu() {
            this.overlayMenuActive = false;
            this.mobileMenuActive = false;
        },
        isDesktop() {
            return window.innerWidth > 1024;
        },
        isHorizontal() {
            return this.layoutMode === 'horizontal';
        },
        onLayoutModeChange(layoutMode) {
            this.layoutMode = layoutMode;
            this.staticMenuDesktopInactive = false;
            this.overlayMenuActive = false;
        },
        onThemeChange(theme) {
            this.$emit('theme-change', theme);
        },
        onLayoutChange(layout) {
            this.$emit('layout-change', layout);
        },
        onConfigClick() {
            this.configClick = true;
        },
        onConfigButtonClick() {
            this.configActive = !this.configActive;
            this.configClick = true;
        },
        isMobile() {
            return window.innerWidth <= 991;
        },

        showLoading() {
            if (this.refCount > 0) {
                //this.$toast.add({ severity: 'info', summary: 'Cargando ...', detail: this.refCount });
                /*Swal.fire({
                    title: '',
                    text: 'Cargando ...(' + this.refCount + ')',
                    //imageUrl: '../layout/images/loading.gif',
                    showConfirmButton: false,
                    allowOutsideClick: false,
                });
                */
                Swal.close();
            } else {
                Swal.close();
                this.$toast.removeAllGroups();
                //this.$toast.close();
            }
        }        
    },    
    computed: {        
        color1() {
            if (this.store && this.store.getters.currentUser && this.store.getters.currentUser.broker) {
                return this.store.getters.currentUser.broker.options.filter(i => i.id == 'color1')[0].value;
            }
            else {
                return "#1E88E5";
            }

        },
        color2() {
            if (this.store && this.store.getters.currentUser && this.store.getters.currentUser.broker) {

                return HelperCommon.cambiarLuminosidadColor(this.store.getters.currentUser.broker.options.filter(i => i.id == 'color1')[0].value, -0.2);
            }
            else {
                return "#0D47A1";
            }
        },
        getItemsTabs() {
            return this.itemsTabs.value ?? [];
        },
        containerClass() {
            return [
                'layout-wrapper',
                {
                    'layout-menu-overlay': this.layoutMode === 'overlay',
                    'layout-menu-overlay-active': this.overlayMenuActive,
                    'layout-mobile-active': this.mobileMenuActive,
                    'layout-menu-horizontal': this.layoutMode === 'horizontal',
                    'p-input-filled': this.$primevue.config.inputStyle === 'filled',
                    'p-ripple-disabled': this.$primevue.config.ripple === false,
                },
                {
                    'layout-fullwidth': true, // this.$appState.fullWidth
                },
            ];
        },

        getCookieDomnain() {
            return this.store.getters.getKey(ConfigurationConst.COOKIES) ?? '';
        },

        portalTimeOut() {
            const key = this.store.getters.getKey(ConfigurationConst.TIMEOUT);
            if (key) {
                const converter = HelperCommon.toNumber(key);
                if (converter > 0) {
                    return converter;
                }
            }
            return 60000;
        }
    },
    components: {
        AppTopBar: AppTopBar,
        //'AppConfig': AppConfig,
        AppMenu: AppMenu,
        AppFooter: AppFooter,
        AppActionBar: AppActionBar,
        Vidle
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.getWindowWidth);
    },
    created() {
        ApiNostromo.vueInstance.axios.interceptors.request.use(            
            (request) => {                                             
                return request;
            },
            (error) => {                 
                HelperException.logExceptionApiService(error);
                return Promise.reject(error);
            }
        );

        ApiNostromo.vueInstance.axios.interceptors.response.use(
            (response) => {                                        
                HelperException.logExceptionApiServiceData(response.data, response.request.responseURL, this.store);                
                return response;
            },
            (error) => {                                 
                HelperException.logExceptionApiService(error);
                return Promise.reject(error);
            }
        );
    },
};
</script>
<style>
@import './App.scss';
@import '~sweetalert2/dist/sweetalert2.css';
@import "~@fortawesome/fontawesome-free/css/all.min.css";
</style>

<style>
#contenedorwp {
    --primary: v-bind(color1);
    --secondary: v-bind(color2);
    background-color: var(--primary);
    background-image: -webkit-gradient(linear, left top, right top, from(--primary), to(--secondary));
    background-image: -webkit-linear-gradient(to right, --primary, --secondary);
    background-image: -moz-linear-gradient(to right, --primary, --secondary);
    background-image: -ms-linear-gradient(to right, --primary, --secondary);
    background-image: -o-linear-gradient(to right, --primary, --secondary);
    background-image: linear-gradient(to right, var(--primary), var(--secondary));
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=--primary, endColorstr=--secondary, gradientType="1");

}

.p-scrolltop.p-link {
    background: v-bind(color1) !important;
}

.color-correduria {
    color: v-bind(color1) !important;
}
</style>