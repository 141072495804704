
import {computed, inject, ref} from 'vue'
import PlatformType from '../constantes/PlatformType'

export default function logoComp(){

    const platform = ref ('')
    const ipublicPath = inject('publicPath');
    
        
    const getLogoPanelUrl = computed(() =>{
        
        let urlLogo = `${ipublicPath}layout/images/logo_wtw.svg`;
        if (platform.value){                
            if (platform.value.toLowerCase()=== PlatformType.AM ){
                urlLogo = `${ipublicPath}layout/images/logo_aunna.png`;
            }
        }

        return urlLogo;
    })

    const getLogoUrl = computed(() =>{          
        let urlLogo = `${ipublicPath}layout/images/logo_wp.png`;
        if (platform.value){                   
            if (platform.value.toLowerCase()=== PlatformType.AM ){
                urlLogo = `${ipublicPath}layout/images/logo_aunna.png`;
            }
        }

        return urlLogo;
    })

    return {
        platform,
        ipublicPath,

        getLogoPanelUrl,
        getLogoUrl
    }
}