import { createStore } from 'vuex';
import { config } from 'vuex-module-decorators';
import createPersistedState from 'vuex-persistedstate';
import AuthModule from './modules/AuthModule';
import ConfigurationModule from './modules/ConfigurationModule';
import EncryptionModule from './modules/EncryptionModule';
import SecureLS from '@cbcheng/secure-ls';

config.rawError = true;


const ls = new SecureLS({ isCompression: false, encodingType: 'aes' });

const store = createStore({
    modules: {
        AuthModule,
        ConfigurationModule,
        EncryptionModule
    },
    plugins: [
        createPersistedState({
            key: 'FrontCommonData',
            storage: {
                getItem: (key) => ls.get(key),
                setItem: (key, value) => ls.set(key, value),
                removeItem: (key) => ls.remove(key),
            },
            //storage:sessionStorage           
        }),
    ],
});

export default store;
