<template>
    <div class="grid">
        <div class="col-12">
            <h5><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> {{(isError ?? false ? 'Error' :'Sugerencia') }}</h5>
            <div class="card">
                <div class="p-fluid formgrid grid">
                    <div class="field col-3">
                        <label for="title"
                            :class="{ 'p-invalid': v$.title.$invalid && submitted, 'customrequired': true }">Título</label>
                        <InputText id="title" v-model="data.title"
                            :class="{ 'p-invalid': v$.title.$invalid && submitted }" />
                        <small v-if="(v$.title.$invalid && submitted) || v$.title.$pending.$response" class="p-error">{{
                        v$.title.required.$message }}</small>
                    </div>
                    <div class="field col-3">
                        <label for="name">Nombre</label>
                        <InputText id="name" v-model="data.name"></InputText>
                    </div>
                    <div class="field col-3">
                        <label for="phone">Teléfono</label>
                        <InputText id="phone" v-model="data.phone"></InputText>
                    </div>
                    <div class="field col-3">
                        <label for="fechaDesde"
                            :class="{ 'p-invalid': v$.description.$invalid && submitted, 'customrequired': true }">Por
                            favor, describa la sugerencia de forma detallada:</label>
                        <Textarea id="complementAddress" type="text" v-model="data.description" :autoResize="true"
                            rows="4" cols="30" :class="{ 'p-invalid': v$.description.$invalid && submitted }" />
                        <small v-if="(v$.description.$invalid && submitted) || v$.description.$pending.$response"
                            class="p-error">{{ v$.description.required.$message }}</small>
                    </div>
                </div>
                <Toolbar class="mb-4">
                    <template v-slot:end>
                        <Button id="enviar" label="Enviar" @click="sendSuggest()" icon="pi pi-send"
                            class="p-button p-component" />
                    </template>
                </Toolbar>
            </div>
        </div>

    </div>

</template>
<script lang="ts">

import { defineComponent, ref } from 'vue';
import { suggestModel } from '../../../../interfaces/nostromo/service/generic/suggestModel';
import useVuelidate from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import HelperReport from '../../../../funciones/HelperReport';
import MessageService, { MessageType } from '../../../../servicios/MessageService';
import { useStore } from 'vuex';
import HelperLoading from '../../../../funciones/HelperLoading';
import HelperCommon from '../../../../funciones/HelperCommon';

export default defineComponent({
    name: 'sugerencias',
    props: ['isError'],
    emits:['doClose'],
    setup(props,context) {

        const store = useStore();
        let data = ref<suggestModel>({
            sessionID: store.getters.sessionID,
            title: '',
            name: '',
            phone: '',
            description: ''

        });

        const submitted = ref(false);


        const rules = {

            title: {
                required: helpers.withMessage('Debe especificar el titulo ', required),
            },
            description: {
                required: helpers.withMessage('Debe especificar la descripción', required),
            },
        };



        const v$ = useVuelidate(rules, data as any);




        const isValidData = (): boolean => {
            submitted.value = true;

            // se valida todo
            v$.value.$touch();
            if (v$.value.$invalid) {
                HelperCommon.setFocusOnFirstError();
                return false;
            }

            return true;
        }



        const sendSuggest = () => {
            if (isValidData()) {
                HelperLoading.showLoading();
                if (!(props.isError ?? false)) {
                    HelperReport.setSuggest(data.value as any).then((response) => {
                        if (response) {
                            MessageService.showToast(MessageType.Correcto, '', 'Se ha generado la sugerencia');
                            initData();
                            context.emit('doClose');

                        }
                    }).finally(() => {
                        HelperLoading.hideLoading();
                    })
                }
                else {
                    HelperReport.setError(data.value as any).then((response) => {
                        if (response) {
                            MessageService.showToast(MessageType.Correcto, '', 'Se ha informado del error');
                            initData();
                            context.emit('doClose');
                        }
                    }).finally(() => {
                        HelperLoading.hideLoading();
                    })
                }

            }
        }

        const initData = () => {
            data.value = {
                sessionID: store.getters.sessionID,
                title: '',
                name: '',
                phone: '',
                description: ''

            }
            submitted.value = false;
        }



        return {
            store,
            data,
            submitted,
            isValidData,
            sendSuggest,
            initData,
            rules,
            v$,
        };
    },
});
</script>
<style scoped>

</style>
