<template>
    <div class="exception-body accessdenied">
        <div class="mobile-banner">
            <img src="layout/images/logo-mobile.svg" alt="prestige-layout" />
        </div>
        <div class="exception-panel">
            <div class="logo">
                <img src="layout/images/logo.svg" alt="prestige-layout" />
            </div>
        </div>
        <div class="exception-card">
            <div class="top-border"></div>
            <div class="exception-content">
                <h1>Access Denied</h1>
                <p>You don't have the necessary permissions.</p>
                <router-link to="/">
                    <Button id="gotodash" label="Go To Dashboard" />
                </router-link>
            </div>
        </div>
    </div>
</template>

<script></script>

<style scoped>

</style>
