<template>
<body>
    <div class="exception-body notfound">
        <div class="exception-card">
            <div class="top-border"></div>
            <div class="exception-content">
                <h1>Página no encontrada</h1>
                <router-link to="/">
                    <Button  id="gotoportal" label="Volver al portal" />
                </router-link>
            </div>
        </div>
    </div>
    </body>
</template>

<script></script>

<style scoped></style>
