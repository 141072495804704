import ApiNostromo from '../servicios/ApiNostromo';
import { getExternalUrlRequest } from '../interfaces/nostromo/service/getExternalUrlRequest';

export const ENDPOINT_GET_URL = '/external/getExternalUrl';

const getExternalUrl = (sessionId, userId, brokerId, type, withOptions = false): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
        const request: Partial<getExternalUrlRequest> = {
            brokerId: brokerId,
            userId: userId,
            sessionID: sessionId,
            withOptions: withOptions,
            type: type,
        };
        ApiNostromo.post(ENDPOINT_GET_URL, request as any)
            .then((response) => {
                resolve(response.data);
            })
            .catch(() => {
                reject();
            });
    });
};

export default {
    getExternalUrl,
};
