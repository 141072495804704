<template>
    <div class="layout-footer willplatine">
        <div class="grid justify-content-start mt-2">
            <img :src="getLogoUrl" style="width: 100px" class="mr-2" />
            <p v-if="footerText">{{footerText}} </p>
            <i v-else class="pi pi-spin pi-spinner" style="font-size: 1rem"></i>
            <div v-if="getCookieDomnain">
                <Button id="ot-sdk-btn" label="Cookie Settings" class="p-button-success ot-sdk-show-settings ml-5" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">

import { computed, defineComponent, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import ConfigurationConst from '@frontcommon/shared/src/constantes/ConfigurationConst';
import HelperConfiguration from '@frontcommon/shared/src/funciones/HelperConfiguration';
import HelperLoading from '@frontcommon/shared/src/funciones/HelperLoading';
import HelperCommon from '@frontcommon/shared/src/funciones/HelperCommon';
import logoComp from '@frontcommon/shared/src/funcionescompartidas/logoComp';

export default defineComponent({
    name: 'AppFooter',
    setup() {
        const {getLogoUrl, ipublicPath} = logoComp();
        const store = useStore();        
        let footerText = ref('');

        const getFooterText = () => {
            HelperLoading.showLoading();

            HelperConfiguration.getVersion().then((data) => {
                footerText.value += data.version + ' | ' +
                    HelperCommon.getCurrentDateTimeLongFormat() + ' | ' + store.getters.getKey(ConfigurationConst.COPYRIGHT);
            }).finally(() => {
                HelperLoading.hideLoading();
            })

        }


        const getCookieDomnain = computed(() => {
            return store.getters.getKey(ConfigurationConst.COOKIES) ?? '';
        })



        onMounted(()=>{
            getFooterText();
        })

        return {
            footerText,
            getCookieDomnain,
            ipublicPath,
            getLogoUrl
        };
    },
});
</script>

<style scoped>

</style>
