import ApiNostromo from '../servicios/ApiNostromo';
import { useStore } from 'vuex';
import MessageService, { MessageType } from '../servicios/MessageService';
import { newGuid } from './HelperUtils';
import HelperCommon from './HelperCommon';
import HelperCrypto from './HelperCrypto';
import { useRouter } from 'vue-router';
import { message } from '../interfaces/nostromo/service/generic/message';

function setError(data) {
    if (data) {
        ApiNostromo.post('/error/logErrWithoutSession', data);
    }
}

// err: error trace
// vm: component in which error occured
// info: Vue specific error information such as lifecycle hooks, events etc.
const logException = (err, vm, info): void => {
    if (err && (err.message == "Cannot read properties of null (reading 'style')" || err.message == "Cannot set properties of null (setting 'value')")) {
        return;
    }

    const lastErrorRefId = newGuid();

    if (process.env.VUE_APP_DEBUGGING == 'true') {
        MessageService.showMessage(MessageType.Error, 'Error en la aplicación', JSON.stringify(err.stack));
    } else {
        const msg = 'ReferenciaId:' + lastErrorRefId + ' . Consulte el log para ver el detalle';
        MessageService.showMessage(MessageType.Error, 'Error en la aplicación', msg);
    }

    const data = {
        userAgent: navigator.userAgent,
        errorType: 'Component Error',
        message: 'Error en el componente',
        errmessage: err.message,
        stack: err.stack,
        commponent: vm,
        info: info,
    };

    const trace = {
        error: JSON.stringify(data),
        errorRefId: lastErrorRefId,
    };

    setError(trace);
};

const logExceptionW = (message, source, lineno, colno, error): void => {
    // error en los calendar
    if (source && source.toString().includes('utils.esm.js')) {
        return;
    }

    // error en los calendar
    if (message && message == "Uncaught TypeError: Cannot read properties of null (reading 'querySelector')") {
        return;
    }

    const lastErrorRefId = newGuid();
    const msg = 'ReferenciaId:' + lastErrorRefId + ' .' + message + ' . Consulte el log para ver el detalle';
    MessageService.showMessage(MessageType.Error, 'Error en la aplicación', msg);

    const data = {
        userAgent: navigator.userAgent,
        errorType: 'Window Error',
        message: message,
        source: source,
        lineno: lineno,
        colno: colno,
        error: error,
    };

    const trace = {
        error: JSON.stringify(data),
        errorRefId: lastErrorRefId,
    };
    setError(trace);
};
const logExceptionApiService = (error, store): void => {
    const router = useRouter();

    if (error.request.responseURL.endsWith('/security/logout')) {
        return;
    }
    let tittle = 'Error';
    let message = '';
    let response;

    if (error.response && error.response.request && error.response.request.responseURL) {
        const decryptedText = HelperCrypto.Decrypt(error.response.request.responseURL, error.response.data, store);
        response = HelperCommon.jsonIfParse(decryptedText, response);
    }

    if (response && response.msgCode && (response.msgCode == 'bllSecurity.login.001' || response.msgCode == 'bllSecurity.checkSecurity.002')) {
        //const router = useRouter();
        if (router && router.currentRoute.value.name == 'login') {
            tittle = 'Error de acceso al sistema';
        }
        if (router && router.currentRoute.value.name != 'login' && response.msgCode == 'bllSecurity.checkSecurity.002') {
            MessageService.showMessage(MessageType.Error, tittle, response.message.toString(), true, false, false, '', HelperCommon.salir);
        } else {
            MessageService.showMessage(MessageType.Error, tittle, response.message.toString());
        }
    } else {
        if (response && response.message) {
            message = response.message;
        } else {            
            message = error.message;
        }
        MessageService.showMessage(MessageType.Error, tittle, message);
    }
};
const logExceptionApiServiceData = (data, url, store): void => {
    let response;
    if (data) {
        const decryptedText = HelperCrypto.Decrypt(url, data, store);
        response = HelperCommon.jsonIfParse(decryptedText, response);
    }
    if (response) {
        const msgs = response.messages ?? [];
        if (response.error) {            
            const msg_str = msgs.map((value) => value.description).join(',');
            MessageService.showMessage(MessageType.Error, 'Error', msg_str);
        }        
    }
};

export default {
    logException,
    logExceptionW,
    logExceptionApiService,
    logExceptionApiServiceData,
};
