export default class ConfigurationConst{
    static URL_NOSTROMO = 'nostromo.tarificador' as string;
    static COPYRIGHT = 'Portal.copyright' as string;
    static COOKIES = 'Portal.OneTrustCookies.data-domain-script' as string;
    static TIMEOUT = 'Portal.timeOut' as string;
    static NUMMAXTABSOPORTUNIDADES = 'Portal.num_tabs_oportunidades' as string;
    static PLATFORM = 'Portal.Platform' as string;
    static MAXUPLOADFILE = 'Portal.MaxMbUploadFile'
    static ECNCRYPTREQUEST = 'Portal.EncryptRequest'
    static ECNCRYPTRESPONSE = 'Portal.EncryptResponse'

}