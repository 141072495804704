import DirectiveConst from "../constantes/DirectiveConst";
import HelperSecurity from "./HelperSecurity";

const validateDirective = (user,directive) =>{
        return HelperSecurity.validateDirective(user,directive);
}

const canSeeAllOportunidades = (user)=>{
    return  HelperSecurity.validateDirective(user, DirectiveConst.GET_ALL_BROKER_RFPS);
}

const canSeeOportunidades = (user)=>{
    return  HelperSecurity.validateDirective(user, DirectiveConst.RFP_ACCESO);
}

const canDeleteOportunidades = (user)=>{
    return  HelperSecurity.validateDirective(user, DirectiveConst.DELETE_RFP);
}

const canSeeEstadisticas = (user)=>{
    return HelperSecurity.validateDirective(user, DirectiveConst.ESTADISTICAS);
}

const canMassReject = (user)=>{
    return HelperSecurity.validateDirective(user, DirectiveConst.RECHAZO_MASIVO);
}

const canEditPte_Solicitar_Cia = (user)=>{
    return HelperSecurity.validateDirective(user,DirectiveConst.PTE_SOLICITAR_CIA + DirectiveConst.EDIT);
}


const canSeePte_Solicitar_Cia = (user)=>{    
    return HelperSecurity.validateDirective(user,DirectiveConst.PTE_SOLICITAR_CIA);    
}


const canEditPte_Respuesta_Cia = (user)=>{
    return HelperSecurity.validateDirective(user,DirectiveConst.PTE_RESPUESTA_CIA + DirectiveConst.EDIT);    
}


const canSeePte_Respuesta_Cia = (user)=>{
    return HelperSecurity.validateDirective(user,DirectiveConst.PTE_RESPUESTA_CIA);
}

const canSave_Policy_Data = (user)=>{
    return HelperSecurity.validateDirective(user,DirectiveConst.SAVE_POLICY_DATA);
    
}

const canSeePte_Presentar_Cliente = (user)=>{
    return HelperSecurity.validateDirective(user,DirectiveConst.PTE_PRESENTAR_CLIENTE);    
}
const canEditHonorarios = (user) : boolean => {
    return  HelperSecurity.validateDirective(user, DirectiveConst.CAN_EDIT_HONORARIOS);
}

 export default{
    canSeeAllOportunidades,
    validateDirective,
    canSeeOportunidades,
    canDeleteOportunidades,
    canSeeEstadisticas,
    canMassReject,
    canSave_Policy_Data,
    canEditPte_Solicitar_Cia,
    canSeePte_Solicitar_Cia,
    canEditPte_Respuesta_Cia,
    canSeePte_Respuesta_Cia,
    canSeePte_Presentar_Cliente,
    canEditHonorarios
}