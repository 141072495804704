import MessageService, { MessageType } from '../servicios/MessageService';
const isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/;

export const newGuid = (): string => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (Math.random() * 16) | 0,
            v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
};

export const RemoveObjectById = (id, arr) => {
    const indexOfObject = arr.findIndex((obj) => {
        return obj.id === id;
    });

    if (indexOfObject !== -1) {
        arr.splice(indexOfObject, 1);
    }
    return arr;
};

const isIsoDateString = (value): boolean => {
    return value && typeof value === 'string' && isoDateFormat.test(value);
};

const handleDates = (body) => {
    if (body === null || body === undefined || typeof body !== 'object') return body;

    for (const key of Object.keys(body)) {
        const value = body[key];
        if (isIsoDateString(value)) body[key] = new Date(value);
        else if (typeof value === 'object') handleDates(value);
    }
};

const isNumeric = (str) => {
    if (str == '' || str == null || str == undefined) return false;
    if (typeof str != 'string' || !str) return false;

    try {
        str = str.replaceAll(' ', '');

        return !isNaN(str) && !isNaN(parseFloat(str));
    } catch {
        return false;
    }
};

const groupByToMap = <T, Q>(array: T[], predicate: (value: T, index: number, array: T[]) => Q) =>
    array.reduce((map, value, index, array) => {
        const key = predicate(value, index, array);
        map.get(key)?.push(value) ?? map.set(key, [value]);
        return map;
    }, new Map<Q, T[]>());

const lockEdit = (canEdit, data) => {
    if (!canEdit && data) {
        for (const propt in data) {
            data[propt] = true;
        }
    }
};

const add_years = (dt,n) =>
 {
    return new Date(dt.setFullYear(dt.getFullYear() + n));      
 }

 const copyToClipBoard = (texto) =>{
    navigator.clipboard.writeText(texto);
        MessageService.showToast(MessageType.Correcto, '', 'Se han copiado los registros al portapapeles.');

}

export default { newGuid, RemoveObjectById, handleDates, isNumeric, groupByToMap, lockEdit,add_years,copyToClipBoard };
