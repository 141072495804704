import SecureLS from '@cbcheng/secure-ls';

const ID_TOKEN_KEY = 'id_token' as string;
const CONF = 'settings' as string;
const CONF_MENU_TARIFICADOR = 'mnutarificador' as string;
const BASE_CATALOGS = 'basecat' as string;

const ls = new SecureLS({ isCompression: false, encodingType: 'aes' });

/**
 * @description get value form localStorage
 */
export const getValue = (key: string): string | null => {
    return ls.get(key);

    //return window.sessionStorage.getItem(key);
};

/**
 * @description save value into localStorage
 * @param token: string
 */
export const setValue = (key: string, value: string): void => {
    ls.set(key, value);
    //window.sessionStorage.setItem(key, value);
};

/**
 * @description remove value form localStorage
 */
export const destroy = (key: string): void => {
    ls.remove(key);
    //window.sessionStorage.removeItem(key);
};

/**
 * @description remove value form localStorage
 */
export const destroyAll = (): void => {
    window.sessionStorage.removeItem(ID_TOKEN_KEY);
    window.sessionStorage.removeItem(CONF);
    window.sessionStorage.removeItem(CONF_MENU_TARIFICADOR);
    window.sessionStorage.removeItem(BASE_CATALOGS);
};

export default { getValue, setValue, destroy, destroyAll, CONF, CONF_MENU_TARIFICADOR, BASE_CATALOGS };
