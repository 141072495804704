<template>
    <Dialog v-model:visible="show" :modal="true" :closable="false" :closeOnEscape="false" :showHeader="false" contentClass="portal-loader">
        <div class="p-dialog-mask p-component-overlay p-component-overlay-enter" style="z-index:999999">
            <i class="pi pi-spin pi-spinner mr-2" style="font-size: 4rem;color: #2196F3;"></i>
        </div>
    </Dialog>

</template>

<script lang="ts">

import { defineComponent, ref } from 'vue';

export default defineComponent({
    name: 'cargando',
    setup() {
        let show = ref(false);
        function mostrar() {
            show.value = true;
        };
        function ocultar() {
            show.value = false;
        };
        return {
            show,
            mostrar,
            ocultar,

        };
    }
});
</script>
<style>

</style>
