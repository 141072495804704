import { whoAmIRequest } from "../../interfaces/nostromo/service/whoAmIRequest";


export class _whoAmiRequest implements whoAmIRequest {
    withInsuranceLines = false;
    withSegments = false;
    sessionID: string | null = null;

    constructor(withInsuranceLines: boolean, withSegments: boolean, sessionID: string | null) {
        this.withInsuranceLines = withInsuranceLines;
        this.withSegments = withSegments;
        this.sessionID = sessionID;
    }
}
