import ApiNostromo from '../servicios/ApiNostromo';
import store from '../almacen';
import { Actions } from '../almacen/enums/StoreEnums';
import CookieService from '../servicios/CookieService';
import MessageService, { MessageType } from '../servicios/MessageService';
import HelperSecurity from './HelperSecurity';
import html2canvas from 'html2canvas';
import { useRouter } from 'vue-router';
const router = useRouter();


const salir = (sessionId): void => {
    HelperSecurity.logout(sessionId);
    MessageService.showMessage(MessageType.Info, 'Cerrando entorno', 'Por favor espere...', false);    

    store.dispatch(Actions.LOGOUT).then(() => {
        CookieService.destroyAll();
        MessageService.hideAllMessages();
        if (router){
            router.push({ name: 'login' });
        }        
    });
};

const formateaFechaHoraLarga = (value) => {
    if (!value) {
        return null;
    }
    const date = new Date(value);
    return date.toLocaleString('es-ES', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit',second: '2-digit' });
};
function padLeft(num, dig) {
    return num.toString().padStart(dig, '0');
}
const formateaFechaHora = (value) => {
    if (!value) {
        return null;
    }
    const date = new Date(value);
    return [padLeft(date.getDate(), 2), padLeft(date.getMonth() + 1, 2), date.getFullYear()].join('/') + ' ' + [padLeft(date.getHours(), 2), padLeft(date.getMinutes(), 2)].join(':');
};
const formateaFecha = (value) => {
    if (!value) {
        return null;
    }
    const date = new Date(value);
    return date.toLocaleDateString('es-ES', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    });
};

const diferenciaFechasEnDias = (fecha1, fecha2) => {
    const diffInMs = Math.abs(fecha2 - fecha1);
    return Math.ceil(diffInMs / (1000 * 60 * 60 * 24));
};

const obtenerTextoMensajes = (response) => {
    const msgs = response.messages ?? [];
    const msg_str = msgs.map((value) => value.description).join(',');
    return msg_str;
};

const TestServiceMail = (sessionId: string): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {
        ApiNostromo.post('/test/testServiceMail?sessionID=' + sessionId, {})
            .then((response) => {
                resolve(response.data);
            })
            .catch(() => {
                reject();
            });
    });
};

const getDomUploader = (dv, options) => {
    const root = dv.currentTarget ?? dv;
    Array.prototype.forEach.call(root.getElementsByClassName('p-fileupload-filename'), function (element) {
        if (element.previousSibling.innerHTML.indexOf('<option') == -1) {
            const div = document.createElement('div');

            const input = document.createElement('input');
            input.className = 'p-inputtext p-component';
            input.id = 'description_' + element.innerText;
            input.placeholder = 'Descripción';
            input.title = 'Descripción';
            div.append(input);
            insertAfter(div, element.previousSibling);

            if (options != null) {
                const selectList = document.createElement('select');
                selectList.id = 'type_' + element.innerText;
                selectList.className = 'p-dropdown p-inputtext p-placeholder';
                selectList.title = 'Tipo de documento';
                selectList.setAttribute('pepe', '');

                for (let i = 0; i < options.length; i++) {
                    const option = document.createElement('option');
                    option.value = options[i].id;
                    option.text = options[i].description;
                    selectList.appendChild(option);
                }

                insertAfter(selectList, element.previousSibling);
            }
        }
    });
};

const insertAfter = (newElement, referenceElement) => {
    referenceElement.parentNode.insertBefore(newElement, referenceElement.nextSibling);
};

const toBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.onerror = (error) => reject(error);
    });

const formateaMoneda = (valor) => {
    if (!valor) {
        return null;
    }
    const formatter = new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR' });
    return formatter.format(valor);
};

const navegarAlDocumento = (url) => {
    window.open(url, '_blank');
};

const validarEmail = (email) => {
    if (typeof email === 'undefined' || email === null || email === '') {
        return true;
    }
    return /^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*;{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,10})[\W]*$/.test(email);
};
function texoEstaVacio(texto) {
    return texto === null || texto.match(/^ *$/) !== null;
}
function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}
function cambiarLuminosidadColor(hex, lum) {
    //esta funcion devuelve un código de color un % mas oscuro o claro del color indicado.
    //hex: el código hexadecimal del color (ej: #123456)
    //lum: el factor de luminosidad, es decir, -0,1 es un 10 % más oscuro, 0,2 es un 20 % más claro, etc.
    lum = lum || 0;
    hex = String(hex).replace(/[^0-9a-f]/gi, '');
    if (hex.length < 6) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    let rgb = '#',
        c,
        i;
    for (i = 0; i < 3; i++) {
        c = parseInt(hex.substr(i * 2, 2), 16);
        c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
        rgb += ('00' + c).substr(c.length);
    }

    return rgb;
}

const validarIban = (iban) => {
    if (typeof iban === 'undefined' || iban === null || iban === '') {
        return false;
    }

    if (iban.length < 20) {
        return false;
    }

    return /^ES\d{22}$/.test(iban);
};

const toNumber = (valor) => {
    if (typeof valor === 'undefined' || valor === null || valor === '') {
        return 0;
    }

    return Number(valor.replace('.', '').replace(',', '.'));
};

const getCurrentDateTimeLongFormat = () => {
    return new Date().toLocaleDateString('es-ES', {
        weekday: 'long',
        hour12: false,
        hour: 'numeric',
        minute: 'numeric',
    });
};

const stringToBoolean = (stringValue) => {
    switch(stringValue?.toLowerCase()?.trim()){
        case "true": 
        case "yes": 
        case "1": 
          return true;

        case "false": 
        case "no": 
        case "0": 
        case "": 
        case null: 
        case undefined:
          return false;

        default: 
          return JSON.parse(stringValue);
    }
}

const setFocusOnFirstError = () =>{
    setTimeout( () => {
        for (let i = 0; i < document.getElementsByClassName('p-invalid').length -1; i++) {
            const ctrl = document.getElementsByClassName('p-invalid')[i] as any;
            ctrl.focus();
            ctrl.scrollIntoView();
          }
    }
    , 100);    
}

const  isNullOrWhitespace = ( input ) => {
    try
    {
        return !input || !input.trim();
    }
    catch
    {
        return false;
    }
    
  }

  const prettifyXml = (sourceXml) =>
{
    try{
        const xmlDoc = new DOMParser().parseFromString(sourceXml, 'application/xml');
        const xsltDoc = new DOMParser().parseFromString([
            // describes how we want to modify the XML - indent everything
            '<xsl:stylesheet xmlns:xsl="http://www.w3.org/1999/XSL/Transform">',
            '  <xsl:strip-space elements="*"/>',
            '  <xsl:template match="para[content-style][not(text())]">', // change to just text() to strip space in text nodes
            '    <xsl:value-of select="normalize-space(.)"/>',
            '  </xsl:template>',
            '  <xsl:template match="node()|@*">',
            '    <xsl:copy><xsl:apply-templates select="node()|@*"/></xsl:copy>',
            '  </xsl:template>',
            '  <xsl:output indent="yes"/>',
            '</xsl:stylesheet>',
        ].join('\n'), 'application/xml');
    
        const xsltProcessor = new XSLTProcessor();    
        xsltProcessor.importStylesheet(xsltDoc);
        const resultDoc = xsltProcessor.transformToDocument(xmlDoc);
        const resultXml = new XMLSerializer().serializeToString(resultDoc);
        return resultXml;
    }
    catch(ex)
    {
        return sourceXml;
    }
    
};

const getExtensionFile = (fileName)=>{
    if (fileName){
        const regex = new RegExp('[^.]+$');
        return fileName.match(regex);
    }

    return '';
}

const getCanvas = (elementId:string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
        
        const el = document.getElementById(elementId);

        if (el) {
            const options = {
                //type: 'dataURL',
                logging: true,
                backgroundColor: 'white',
                foreignObjectRendering: false,
                removeContainer: true,
                allowTaint: true,
                useCORS: true
            } as any
            html2canvas(el, options).then(data => {
                resolve (data);

            })
            .catch(() => {
                reject();
            });
        }
        else{
            resolve (null);
        }
    });
};


const getBase64FromUrl = async (url) => {
    const img = new Image();
    img.setAttribute('crossOrigin', 'anonymous');

    return new Promise((resolve) => {
        img.onload = () => {
          const canvas = document.createElement("canvas");          
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext("2d");
          if (ctx){

          
          ctx.drawImage(img, 0, 0);
          const dataURL = canvas.toDataURL("image/png");
          resolve(dataURL);
          }
          else{
            resolve ('');
          }
        }
        img.src = url;
    });
  }

function jsonIfParse(str, parsed) {
    try {
        parsed = JSON.parse(str);
        return parsed;
    } catch (e) {
        return str
    }
    
}
const jsonRevive = function (key, value) {
    // const reISO = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/;
    // /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}/;
    const reISO  = /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}/;
    const reMsAjax = /^\/Date\((d|-|.*)\)[\/|\\]$/;
    
    // first, just make sure the property is a string:
    if (typeof value === 'string') {
        // then, use regex to see if it's an ISO-formatted string
        var a = reISO.exec(value);
        if (a) {
            // if so, Date() can parse it:
            return new Date(value);
        }
        // otherwise, see if it's a wacky Microsoft-format string:
        a = reMsAjax.exec(value);
        if (a) {
            // and perform some jujitsu to make use of it:
            var b = a[1].split(/[-+,.]/);
            return new Date(b[0] ? +b[0] : 0 - +b[1]);
        }
        // here, you could insert any additional tests and parse instructions you like, for other date syntaxes...
    }
    // important: you need to return any values you're not parsing, or they die...
    return value;
};
function millisecondsToTime(duration: number) {
    var milliseconds = Math.floor((duration % 1000) / 100),
      seconds = Math.floor((duration / 1000) % 60),
      minutes = Math.floor((duration / (1000 * 60)) % 60),
      hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
  
    hours = (hours < 10) ? 0 + hours : hours;
    minutes = (minutes < 10) ? 0 + minutes : minutes;
    seconds = (seconds < 10) ? 0 + seconds : seconds;
  
    return hours + ":" + minutes + ":" + seconds + "." + milliseconds;
  }

export default {
    millisecondsToTime,
    salir,
    formateaFecha,
    formateaFechaHoraLarga,
    diferenciaFechasEnDias,
    obtenerTextoMensajes,
    TestServiceMail,
    getDomUploader,
    toBase64,
    formateaMoneda,
    navegarAlDocumento,
    validarEmail,
    texoEstaVacio,
    onlyUnique,
    cambiarLuminosidadColor,
    formateaFechaHora,
    padLeft,
    validarIban,
    toNumber,
    getCurrentDateTimeLongFormat,
    stringToBoolean,
    setFocusOnFirstError,
    isNullOrWhitespace,
    prettifyXml,
    getExtensionFile,
    getCanvas,
    getBase64FromUrl,
    jsonIfParse,
    jsonRevive,

};
