import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "layout-footer willplatine" }
const _hoisted_2 = { class: "grid justify-content-start mt-2" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 1,
  class: "pi pi-spin pi-spinner",
  style: {"font-size":"1rem"}
}
const _hoisted_6 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: _ctx.getLogoUrl,
        style: {"width":"100px"},
        class: "mr-2"
      }, null, 8 /* PROPS */, _hoisted_3),
      (_ctx.footerText)
        ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.footerText), 1 /* TEXT */))
        : (_openBlock(), _createElementBlock("i", _hoisted_5)),
      (_ctx.getCookieDomnain)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_Button, {
              id: "ot-sdk-btn",
              label: "Cookie Settings",
              class: "p-button-success ot-sdk-show-settings ml-5"
            })
          ]))
        : _createCommentVNode("v-if", true)
    ])
  ]))
}