
import { useStore } from 'vuex';
import parametrosLogin from '@frontcommon/shared/src/modelos/parametrosUrl/parametrosLogin';
import { Actions } from '@frontcommon/shared/src/almacen/enums/StoreEnums';

//Helpers
import HelperSecurity from './HelperSecurity';
import HelperLoading from './HelperLoading';

const getParametrosUrl = (urlSearchParams: URLSearchParams | undefined | null): Promise<parametrosLogin | null> => {  
    return new Promise<parametrosLogin | null>(async (resolve) => {
        let parametrosUrl: parametrosLogin = {
            sessionId: null,
            user: null,
            password: null,
        };
        if (!urlSearchParams)    
        {
            resolve(null);
        }
        const query = urlSearchParams?.get("query");
        let sessionId: string | null | undefined;
        let user: string | null | undefined;
        let password: string | null | undefined;
        
        if (query) {
            const store = useStore();                
            const queryString = decodeURIComponent(query);
            await store.dispatch(Actions.DECRYPT, queryString).then((response) => {                                    
                const queryIn = decodeURIComponent(response.data);
                sessionId = HelperSecurity.getQueryParameter("sessionId", queryIn);
                user = HelperSecurity.getQueryParameter("user", queryIn);
                password = HelperSecurity.getQueryParameter("password", queryIn);
            }).finally(() => {
                HelperLoading.hideLoading();
            });
        }
        else {
            sessionId = urlSearchParams?.get("sessionId");
            user = urlSearchParams?.get("user");
            password = urlSearchParams?.get("password");                
        }
        if (sessionId) {                                
            parametrosUrl.sessionId = sessionId;
        }
        else if (user && password) {
            parametrosUrl.user = user;
            parametrosUrl.password = password;
        }
        resolve(parametrosUrl);    
    })
}
export default {
    getParametrosUrl    
}