import EventConst from "../constantes/EventConst";
import EventBus from '../event-bus';


const showLoading = () => {
    EventBus.emit(EventConst.LOADING, true);
}

const hideLoading = () => {
    EventBus.emit(EventConst.LOADING, false);
}


const showSaving = () => {
    EventBus.emit(EventConst.SAVE, true);
}

const hideSaving = () => {
    EventBus.emit(EventConst.SAVE, false);
}


export default { showLoading, hideLoading, showSaving, hideSaving }    