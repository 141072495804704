import ApiNostromo from '../servicios/ApiNostromo';
import { suggestModel } from '../interfaces/nostromo/service/generic/suggestModel';
export const ENDPOINT_SET_SUGGEST = '/report/setSuggest';
export const ENDPOINT_SET_ERROR = '/report/setError';





const setSuggest = (request:suggestModel): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {
            
            ApiNostromo.post(ENDPOINT_SET_SUGGEST, request as any)
                .then((response) => {
                    resolve(response.data ?? false);
                })
                .catch(() => {
                    reject();
                });
    });
};

const setError = (request:suggestModel): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {
            
            ApiNostromo.post(ENDPOINT_SET_ERROR, request as any)
                .then((response) => {
                    resolve(response.data ?? false);
                })
                .catch(() => {
                    reject();
                });
    });
};





export default {
    setSuggest, setError
};
