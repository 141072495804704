export default class DirectiveConst{    
    static EDIT ="Edit"
    static RFP_ACCESO = "rfp_acceso";
    static GET_ALL_BROKER_RFPS = "getAllBrokerRFPs";
    static RFP_LIST = "rfp_list";
    static OFICINA_UNLOCK = "oficina_unlock";
    static MANUALES_OPERATIVOS = "manuales_operativos";
    static REUNIONES = "reuniones";
    static ADMIN = "admin";
    static AUTHORIZE_ISSUANCE = "authorize_issuance";
    static ESTADISTICAS = "stats_acceso";
    static RECHAZO_MASIVO = "massRfpRejection";

    static RFP_UNLOCK = "rfp_unlock";
    static PTE_PRESENTAR_CLIENTE = 'pte_presentar_cliente'
    static PTE_RESPUESTA_CIA = "pte_respuesta_cia"
    static PTE_SOLICITAR_CIA = "pte_solicitar_cia"

    static DELETE_RFP = "deleterfp"
    static SAVE_POLICY_DATA="savepolicydata"
    static CAN_EDIT_HONORARIOS="can_edit_honorarios"

    
   }