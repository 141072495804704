//Funciones
import HelperCommon from './HelperCommon';

//Constantes
import ConfigurationConst from '@frontcommon/shared/src/constantes/ConfigurationConst';
import { Buffer } from "buffer";


function Encrypt (url, data, store): string {                   
    const alwaysEncrypted = IsAlwaysEncrypted(url);  
    const neverEncrypted = NeverEncrypted(url);  
    if ((!neverEncrypted && (alwaysEncrypted || isEncryptedRequest(store))) && data) {
        const paramsText = JSON.stringify(data);
        const enc = new TextEncoder();
        const messageBytesUft8 = enc.encode(paramsText);
        const encryptedData = DcgEncrypt(messageBytesUft8);                
        const paramsBase64 = Buffer.from(encryptedData).toString('base64'); 
        return paramsBase64;
    }
    else
    {
        return data;
    }           
}
function Decrypt (url, data, store):string {             
    const alwaysEncrypted = IsAlwaysEncrypted(url);
    const neverEncrypted = NeverEncrypted(url);
    if ((!neverEncrypted && (alwaysEncrypted || isEncryptedResponse(store))) && data) {
        const encryptedDataFromBase64 = base64ToUint8Array(data);        
        const decryptedData = DcgEncrypt(encryptedDataFromBase64);
        const dec = new TextDecoder();
        const decryptedDataText = dec.decode(decryptedData);  
        return decryptedDataText;        
    }
    else {
        return data;                
    }
}        
function isEncryptedRequest(store):boolean {
    try {
        //const store = useStore();
        let encryptRequest; 
        const encryptRequestStr = store.getters.getKey(ConfigurationConst.ECNCRYPTREQUEST) ?? 'false';
        encryptRequest = HelperCommon.stringToBoolean(encryptRequestStr);
        return encryptRequest;
    }
    catch {
        return true;
    }
}

function isEncryptedResponse(store):boolean {
    try {            
        let encryptResponse;                
        const encryptResponseStr = store.getters.getKey(ConfigurationConst.ECNCRYPTRESPONSE) ?? 'false';
        encryptResponse = HelperCommon.stringToBoolean(encryptResponseStr);
        return encryptResponse;
    }
    catch {
        return true;
    }        
}        

function IsAlwaysEncrypted (url): boolean  {
    const alwaysEncrypted = (url.includes('/configuration/getConfigurationPortal') 
            || url.includes('/version/getVersion')  
            || url.includes('/security/login')
            || url.includes('/rfp/getCommunications')
            || url.includes('/Encryption/doEncrypt')
            || url.includes('/helper/getPortalInitialData')
            || url.includes('/security/passwordSetResetRequest'));
    return alwaysEncrypted;                    
}

function NeverEncrypted (url): boolean  {
    const neverEncrypted = (
        url.includes('statisticsGetFile') 
        || url.includes('api/Integracion')  
    );
    return neverEncrypted;                    
}

function DcgEncrypt(messageBytesUft8: Uint8Array): Uint8Array {                
    let encodedMessage = new Uint8Array(messageBytesUft8);
    for (let i=0 ; i < messageBytesUft8.length; i++) {
        encodedMessage[i] = messageBytesUft8[i]^255;
    }
    return encodedMessage;
}

function base64ToUint8Array(base64) {
    const binary = atob(base64);
    const len = binary.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++)
        bytes[i] = binary.charCodeAt(i);
    return bytes;
}

async function bufferToBase64(buffer) {
    // use a FileReader to generate a base64 data URI:
    const base64url: string = await new Promise(r => {
        const reader = new FileReader()
        reader.onload = () => r(reader.result as any)
        reader.readAsDataURL(new Blob([buffer]))
    });
    // remove the `data:...;base64,` part from the start
    return base64url.slice(base64url.indexOf(',') + 1);
}

export default {
    Decrypt,
    Encrypt,    
};